(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name constants.constant:wineTypes
     *
     * @description
     *
     */
    angular
        .module('constants')
        .constant('wineTypes', [
            'PARCELS_PARAM_VINE_L101',
            'PARCELS_PARAM_VINE_L102',
            'PARCELS_PARAM_VINE_L101_L201',
            'PARCELS_PARAM_VINE_L101_L202',
            'PARCELS_PARAM_VINE_L101_L201_L301',
            'PARCELS_PARAM_VINE_L101_L201_L302',
            'PARCELS_PARAM_VINE_L101_L201_L303',
            'PARCELS_PARAM_VINE_L101_L201_L304',
            'PARCELS_PARAM_VINE_L101_L201_L305',
            'PARCELS_PARAM_VINE_L101_L201_L306',
            'PARCELS_PARAM_VINE_L101_L201_L307',
            'PARCELS_PARAM_VINE_L101_L201_L308',
            'PARCELS_PARAM_VINE_L101_L201_L309',
            'PARCELS_PARAM_VINE_L101_L201_L3010',
            'PARCELS_PARAM_VINE_L101_L201_L3011',
            'PARCELS_PARAM_VINE_L101_L201_L3012',
            'PARCELS_PARAM_VINE_L101_L201_L3013',
            'PARCELS_PARAM_VINE_L101_L201_L3014',
            'PARCELS_PARAM_VINE_L101_L201_L3015',
            'PARCELS_PARAM_VINE_L101_L201_L3016',
            'PARCELS_PARAM_VINE_L101_L201_L3017',
            'PARCELS_PARAM_VINE_L101_L201_L3018',
            'PARCELS_PARAM_VINE_L101_L201_L3019',
            'PARCELS_PARAM_VINE_L101_L201_L3020',
            'PARCELS_PARAM_VINE_L101_L201_L3021',
            'PARCELS_PARAM_VINE_L101_L201_L3022',
            'PARCELS_PARAM_VINE_L101_L201_L3023',
            'PARCELS_PARAM_VINE_L101_L201_L3024',
            'PARCELS_PARAM_VINE_L101_L201_L3025',
            'PARCELS_PARAM_VINE_L101_L201_L3026',
            'PARCELS_PARAM_VINE_L101_L201_L3027',
            'PARCELS_PARAM_VINE_L101_L201_L3028',
            'PARCELS_PARAM_VINE_L101_L201_L3029',
            'PARCELS_PARAM_VINE_L101_L201_L3030',
            'PARCELS_PARAM_VINE_L101_L201_L3031',
            'PARCELS_PARAM_VINE_L101_L201_L3032',
            'PARCELS_PARAM_VINE_L101_L201_L3033',
            'PARCELS_PARAM_VINE_L101_L201_L3034',
            'PARCELS_PARAM_VINE_L101_L201_L3035',
            'PARCELS_PARAM_VINE_L101_L201_L3036',
            'PARCELS_PARAM_VINE_L101_L201_L3037',
            'PARCELS_PARAM_VINE_L101_L201_L3038',
            'PARCELS_PARAM_VINE_L101_L201_L3039',
            'PARCELS_PARAM_VINE_L101_L201_L3040',
            'PARCELS_PARAM_VINE_L101_L201_L3041',
            'PARCELS_PARAM_VINE_L101_L201_L3042',
            'PARCELS_PARAM_VINE_L101_L201_L3043',
            'PARCELS_PARAM_VINE_L101_L201_L3044',
            'PARCELS_PARAM_VINE_L101_L201_L3045',
            'PARCELS_PARAM_VINE_L101_L201_L3046',
            'PARCELS_PARAM_VINE_L101_L201_L3047',
            'PARCELS_PARAM_VINE_L101_L201_L3048',
            'PARCELS_PARAM_VINE_L101_L201_L3048A',
            'PARCELS_PARAM_VINE_L101_L201_L3049',
            'PARCELS_PARAM_VINE_L101_L201_L3050',
            'PARCELS_PARAM_VINE_L101_L201_L3051',
            'PARCELS_PARAM_VINE_L101_L201_L3052',
            'PARCELS_PARAM_VINE_L101_L201_L3053',
            'PARCELS_PARAM_VINE_L101_L201_L3054',
            'PARCELS_PARAM_VINE_L101_L201_L3055',
            'PARCELS_PARAM_VINE_L101_L201_L3056',
            'PARCELS_PARAM_VINE_L101_L201_L3057',
            'PARCELS_PARAM_VINE_L101_L201_L3058',
            'PARCELS_PARAM_VINE_L101_L201_L3059',
            'PARCELS_PARAM_VINE_L101_L201_L3051',
            'PARCELS_PARAM_VINE_L101_L201_L3052',
            'PARCELS_PARAM_VINE_L101_L201_L3053',
            'PARCELS_PARAM_VINE_L101_L201_L3054',
            'PARCELS_PARAM_VINE_L101_L201_L3055',
            'PARCELS_PARAM_VINE_L101_L201_L3056',
            'PARCELS_PARAM_VINE_L101_L201_L3057',
            'PARCELS_PARAM_VINE_L101_L201_L3058',
            'PARCELS_PARAM_VINE_L101_L201_L3059',
            'PARCELS_PARAM_VINE_L101_L201_L3060',
            'PARCELS_PARAM_VINE_L101_L201_L3061',
            'PARCELS_PARAM_VINE_L101_L201_L3062',
            'PARCELS_PARAM_VINE_L101_L201_L3063',
            'PARCELS_PARAM_VINE_L101_L201_L3064',
            'PARCELS_PARAM_VINE_L101_L201_L3065',
            'PARCELS_PARAM_VINE_L101_L201_L3066',
            'PARCELS_PARAM_VINE_L101_L201_L3067',
            'PARCELS_PARAM_VINE_L101_L201_L3068',
            'PARCELS_PARAM_VINE_L101_L201_L3069',
            'PARCELS_PARAM_VINE_L101_L201_L3070',
            'PARCELS_PARAM_VINE_L101_L201_L3071',
            'PARCELS_PARAM_VINE_L101_L201_L3072',
            'PARCELS_PARAM_VINE_L101_L201_L3073',
            'PARCELS_PARAM_VINE_L101_L201_L3074',
            'PARCELS_PARAM_VINE_L101_L201_L3075',
            'PARCELS_PARAM_VINE_L101_L201_L3076',
            'PARCELS_PARAM_VINE_L101_L201_L3077',
            'PARCELS_PARAM_VINE_L101_L201_L3078',
            'PARCELS_PARAM_VINE_L101_L201_L3079',
            'PARCELS_PARAM_VINE_L101_L201_L3080',
            'PARCELS_PARAM_VINE_L101_L201_L3081',
            'PARCELS_PARAM_VINE_L101_L201_L3082',
            'PARCELS_PARAM_VINE_L101_L201_L3083',
            'PARCELS_PARAM_VINE_L101_L201_L3084',
            'PARCELS_PARAM_VINE_L101_L201_L3085',
            'PARCELS_PARAM_VINE_L101_L201_L3086',
            'PARCELS_PARAM_VINE_L101_L201_L3087',
            'PARCELS_PARAM_VINE_L101_L201_L3088',
            'PARCELS_PARAM_VINE_L101_L201_L3089',
            'PARCELS_PARAM_VINE_L101_L201_L3090',
            'PARCELS_PARAM_VINE_L101_L201_L3091',
            'PARCELS_PARAM_VINE_L101_L201_L3092',
            'PARCELS_PARAM_VINE_L101_L201_L3093',
            'PARCELS_PARAM_VINE_L101_L201_L3094',
            'PARCELS_PARAM_VINE_L101_L201_L3095',
            'PARCELS_PARAM_VINE_L101_L201_L3096',
            'PARCELS_PARAM_VINE_L101_L201_L3097',
            'PARCELS_PARAM_VINE_L101_L201_L3098',
            'PARCELS_PARAM_VINE_L101_L201_L3099',
            'PARCELS_PARAM_VINE_L101_L201_L3100',
            'PARCELS_PARAM_VINE_L101_L201_L3101',
            'PARCELS_PARAM_VINE_L101_L201_L3102',
            'PARCELS_PARAM_VINE_L101_L201_L3103',
            'PARCELS_PARAM_VINE_L101_L201_L3104',
            'PARCELS_PARAM_VINE_L101_L201_L3105',
            'PARCELS_PARAM_VINE_L101_L201_L3106',
            'PARCELS_PARAM_VINE_L101_L201_L3107',
            'PARCELS_PARAM_VINE_L101_L201_L3108',
            'PARCELS_PARAM_VINE_L101_L201_L3109',
            'PARCELS_PARAM_VINE_L101_L201_L3110',
            'PARCELS_PARAM_VINE_L101_L201_L3111',
            'PARCELS_PARAM_VINE_L101_L201_L3112',
            'PARCELS_PARAM_VINE_L101_L201_L3113',
            'PARCELS_PARAM_VINE_L101_L201_L3114',
            'PARCELS_PARAM_VINE_L101_L201_L3115',
            'PARCELS_PARAM_VINE_L101_L201_L3116',
            'PARCELS_PARAM_VINE_L101_L201_L3117',
            'PARCELS_PARAM_VINE_L101_L201_L3118',
            'PARCELS_PARAM_VINE_L101_L201_L3119',
            'PARCELS_PARAM_VINE_L101_L201_L3120',
            'PARCELS_PARAM_VINE_L101_L201_L3121',
            'PARCELS_PARAM_VINE_L101_L201_L3122',
            'PARCELS_PARAM_VINE_L101_L201_L3123',
            'PARCELS_PARAM_VINE_L101_L201_L3124',
            'PARCELS_PARAM_VINE_L101_L201_L3125',
            'PARCELS_PARAM_VINE_L101_L201_L3126',
            'PARCELS_PARAM_VINE_L101_L201_L3127',
            'PARCELS_PARAM_VINE_L101_L201_L3128',
            'PARCELS_PARAM_VINE_L101_L201_L3129',
            'PARCELS_PARAM_VINE_L101_L201_L3130',
            'PARCELS_PARAM_VINE_L101_L201_L3131',
            'PARCELS_PARAM_VINE_L101_L201_L3132',
            'PARCELS_PARAM_VINE_L101_L201_L3133',
            'PARCELS_PARAM_VINE_L101_L201_L3134',
            'PARCELS_PARAM_VINE_L101_L201_L3135',
            'PARCELS_PARAM_VINE_L101_L201_L3136',
            'PARCELS_PARAM_VINE_L101_L201_L3137',
            'PARCELS_PARAM_VINE_L101_L201_L3138',
            'PARCELS_PARAM_VINE_L101_L201_L3139',
            'PARCELS_PARAM_VINE_L101_L201_L3140',
            'PARCELS_PARAM_VINE_L101_L201_L3141',
            'PARCELS_PARAM_VINE_L101_L201_L3142',
            'PARCELS_PARAM_VINE_L101_L201_L3143',
            'PARCELS_PARAM_VINE_L101_L201_L3144',
            'PARCELS_PARAM_VINE_L101_L201_L3145',
            'PARCELS_PARAM_VINE_L101_L201_L3146',
            'PARCELS_PARAM_VINE_L101_L201_L3147',
            'PARCELS_PARAM_VINE_L101_L201_L3148',
            'PARCELS_PARAM_VINE_L101_L201_L3149',
            'PARCELS_PARAM_VINE_L101_L201_L3150',
            'PARCELS_PARAM_VINE_L101_L201_L3150',
            'PARCELS_PARAM_VINE_L101_L201_L3151',
            'PARCELS_PARAM_VINE_L101_L201_L3152',
            'PARCELS_PARAM_VINE_L101_L201_L3153',
            'PARCELS_PARAM_VINE_L101_L201_L3154',
            'PARCELS_PARAM_VINE_L101_L201_L3155',
            'PARCELS_PARAM_VINE_L101_L201_L3156',
            'PARCELS_PARAM_VINE_L101_L201_L3157',
            'PARCELS_PARAM_VINE_L101_L202_L302',
            'PARCELS_PARAM_VINE_L101_L202_L303',
            'PARCELS_PARAM_VINE_L101_L202_L304',
            'PARCELS_PARAM_VINE_L101_L202_L305',
            'PARCELS_PARAM_VINE_L101_L202_L306',
            'PARCELS_PARAM_VINE_L101_L202_L307',
            'PARCELS_PARAM_VINE_L101_L202_L308',
            'PARCELS_PARAM_VINE_L101_L202_L309',
            'PARCELS_PARAM_VINE_L101_L202_L3010',
            'PARCELS_PARAM_VINE_L101_L202_L3011',
            'PARCELS_PARAM_VINE_L101_L202_L3012',
            'PARCELS_PARAM_VINE_L101_L202_L3013',
            'PARCELS_PARAM_VINE_L101_L202_L3014',
            'PARCELS_PARAM_VINE_L101_L202_L3015',
            'PARCELS_PARAM_VINE_L101_L202_L3016',
            'PARCELS_PARAM_VINE_L101_L202_L3017',
            'PARCELS_PARAM_VINE_L101_L202_L3018',
            'PARCELS_PARAM_VINE_L101_L202_L3019',
            'PARCELS_PARAM_VINE_L101_L202_L302A',
            'PARCELS_PARAM_VINE_L101_L202_L3020',
            'PARCELS_PARAM_VINE_L101_L202_L3021',
            'PARCELS_PARAM_VINE_L101_L202_L3022',
            'PARCELS_PARAM_VINE_L101_L202_L3023',
            'PARCELS_PARAM_VINE_L101_L202_L3024',
            'PARCELS_PARAM_VINE_L101_L202_L3025',
            'PARCELS_PARAM_VINE_L101_L202_L3026',
            'PARCELS_PARAM_VINE_L101_L202_L3027',
            'PARCELS_PARAM_VINE_L101_L202_L3028',
            'PARCELS_PARAM_VINE_L101_L202_L3029',
            'PARCELS_PARAM_VINE_L101_L202_L3030',
            'PARCELS_PARAM_VINE_L101_L202_L3031',
            'PARCELS_PARAM_VINE_L101_L202_L3032',
            'PARCELS_PARAM_VINE_L101_L202_L3033',
            'PARCELS_PARAM_VINE_L101_L202_L3034',
            'PARCELS_PARAM_VINE_L101_L202_L3035',
            'PARCELS_PARAM_VINE_L101_L202_L3036',
            'PARCELS_PARAM_VINE_L101_L202_L3037',
            'PARCELS_PARAM_VINE_L101_L202_L3038',
            'PARCELS_PARAM_VINE_L101_L202_L3039',
            'PARCELS_PARAM_VINE_L101_L202_L3040',
            'PARCELS_PARAM_VINE_L101_L202_L3041',
            'PARCELS_PARAM_VINE_L101_L202_L3042',
            'PARCELS_PARAM_VINE_L101_L202_L3043',
            'PARCELS_PARAM_VINE_L101_L202_L3044',
            'PARCELS_PARAM_VINE_L101_L202_L3045',
            'PARCELS_PARAM_VINE_L101_L202_L3046',
            'PARCELS_PARAM_VINE_L101_L202_L3047',
            'PARCELS_PARAM_VINE_L101_L202_L3048',
            'PARCELS_PARAM_VINE_L101_L202_L3049',
            'PARCELS_PARAM_VINE_L101_L202_L3050',
            'PARCELS_PARAM_VINE_L101_L202_L3051',
            'PARCELS_PARAM_VINE_L101_L202_L3052',
            'PARCELS_PARAM_VINE_L101_L202_L3053',
            'PARCELS_PARAM_VINE_L101_L202_L3054',
            'PARCELS_PARAM_VINE_L101_L202_L3055',
            'PARCELS_PARAM_VINE_L101_L202_L3056',
            'PARCELS_PARAM_VINE_L101_L202_L3057',
            'PARCELS_PARAM_VINE_L101_L202_L3058',
            'PARCELS_PARAM_VINE_L101_L202_L3059',
            'PARCELS_PARAM_VINE_L101_L202_L3060',
            'PARCELS_PARAM_VINE_L101_L202_L3061',
            'PARCELS_PARAM_VINE_L101_L202_L3062',
            'PARCELS_PARAM_VINE_L101_L202_L3063',
            'PARCELS_PARAM_VINE_L101_L202_L3064',
            'PARCELS_PARAM_VINE_L101_L202_L3065',
            'PARCELS_PARAM_VINE_L101_L202_L3066',
            'PARCELS_PARAM_VINE_L101_L202_L3067',
            'PARCELS_PARAM_VINE_L101_L202_L3068',
            'PARCELS_PARAM_VINE_L101_L202_L3069',
            'PARCELS_PARAM_VINE_L101_L202_L3070',
            'PARCELS_PARAM_VINE_L101_L202_L3071',
            'PARCELS_PARAM_VINE_L101_L202_L3072',
            'PARCELS_PARAM_VINE_L101_L202_L3073',
            'PARCELS_PARAM_VINE_L101_L202_L3074',
            'PARCELS_PARAM_VINE_L101_L202_L3075',
            'PARCELS_PARAM_VINE_L101_L202_L3076',
            'PARCELS_PARAM_VINE_L101_L202_L3077',
            'PARCELS_PARAM_VINE_L101_L202_L3078',
            'PARCELS_PARAM_VINE_L101_L202_L3079',
            'PARCELS_PARAM_VINE_L101_L202_L3080',
            'PARCELS_PARAM_VINE_L101_L202_L3081',
            'PARCELS_PARAM_VINE_L101_L202_L3082',
            'PARCELS_PARAM_VINE_L101_L202_L3083',
            'PARCELS_PARAM_VINE_L101_L202_L3084',
            'PARCELS_PARAM_VINE_L101_L202_L3085',
            'PARCELS_PARAM_VINE_L101_L202_L3086',
            'PARCELS_PARAM_VINE_L101_L202_L3087',
            'PARCELS_PARAM_VINE_L101_L202_L3088',
            'PARCELS_PARAM_VINE_L101_L202_L3089',
            'PARCELS_PARAM_VINE_L101_L202_L3090',
            'PARCELS_PARAM_VINE_L101_L202_L3091',
            'PARCELS_PARAM_VINE_L101_L202_L3092',
            'PARCELS_PARAM_VINE_L101_L202_L3093',
            'PARCELS_PARAM_VINE_L101_L202_L3094',
            'PARCELS_PARAM_VINE_L101_L202_L3095',
            'PARCELS_PARAM_VINE_L101_L202_L3096',
            'PARCELS_PARAM_VINE_L101_L202_L3097',
            'PARCELS_PARAM_VINE_L101_L202_L3098',
            'PARCELS_PARAM_VINE_L101_L202_L3099',
            'PARCELS_PARAM_VINE_L101_L202_L3100',
            'PARCELS_PARAM_VINE_L101_L202_L3101',
            'PARCELS_PARAM_VINE_L101_L202_L3102',
            'PARCELS_PARAM_VINE_L101_L202_L3103',
            'PARCELS_PARAM_VINE_L101_L202_L3104',
            'PARCELS_PARAM_VINE_L101_L202_L3105',
            'PARCELS_PARAM_VINE_L101_L202_L3106',
            'PARCELS_PARAM_VINE_L101_L202_L3107',
            'PARCELS_PARAM_VINE_L101_L202_L3108',
            'PARCELS_PARAM_VINE_L101_L202_L3109',
            'PARCELS_PARAM_VINE_L101_L202_L3110',
            'PARCELS_PARAM_VINE_L101_L202_L3111',
            'PARCELS_PARAM_VINE_L101_L202_L3112',
            'PARCELS_PARAM_VINE_L101_L202_L3113',
            'PARCELS_PARAM_VINE_L101_L202_L3114',
            'PARCELS_PARAM_VINE_L101_L202_L3115',
            'PARCELS_PARAM_VINE_L101_L202_L3116',
            'PARCELS_PARAM_VINE_L101_L202_L3117',
            'PARCELS_PARAM_VINE_L101_L202_L3118',
            'PARCELS_PARAM_VINE_L101_L202_L3119',
            'PARCELS_PARAM_VINE_L101_L202_L3120',
            'PARCELS_PARAM_VINE_L101_L202_L3121',
            'PARCELS_PARAM_VINE_L101_L202_L3122',
            'PARCELS_PARAM_VINE_L101_L202_L3123',
            'PARCELS_PARAM_VINE_L101_L202_L3124',
            'PARCELS_PARAM_VINE_L101_L202_L3125',
            'PARCELS_PARAM_VINE_L101_L202_L3126',
            'PARCELS_PARAM_VINE_L101_L202_L3127',
            'PARCELS_PARAM_VINE_L101_L202_L3128',
            'PARCELS_PARAM_VINE_L101_L202_L3129',
            'PARCELS_PARAM_VINE_L101_L202_L3130',
            'PARCELS_PARAM_VINE_L101_L202_L3131',
            'PARCELS_PARAM_VINE_L101_L202_L3132',
            'PARCELS_PARAM_VINE_L101_L202_L3133',
            'PARCELS_PARAM_VINE_L101_L202_L3134',
            'PARCELS_PARAM_VINE_L101_L202_L3135',
            'PARCELS_PARAM_VINE_L101_L202_L3136',
            'PARCELS_PARAM_VINE_L101_L202_L3137',
            'PARCELS_PARAM_VINE_L101_L202_L3138',
            'PARCELS_PARAM_VINE_L101_L202_L3139',
            'PARCELS_PARAM_VINE_L101_L202_L3140',
            'PARCELS_PARAM_VINE_L101_L202_L3141',
            'PARCELS_PARAM_VINE_L101_L202_L3142',
            'PARCELS_PARAM_VINE_L101_L202_L3143',
            'PARCELS_PARAM_VINE_L102_L201',
            'PARCELS_PARAM_VINE_L102_L202',
            'PARCELS_PARAM_VINE_L102_L203',
            'PARCELS_PARAM_VINE_L102_L201_L301',
            'PARCELS_PARAM_VINE_L102_L201_L302',
            'PARCELS_PARAM_VINE_L102_L201_L303',
            'PARCELS_PARAM_VINE_L102_L201_L304',
            'PARCELS_PARAM_VINE_L102_L201_L305',
            'PARCELS_PARAM_VINE_L102_L201_L306',
            'PARCELS_PARAM_VINE_L102_L201_L307',
            'PARCELS_PARAM_VINE_L102_L201_L308',
            'PARCELS_PARAM_VINE_L102_L201_L309',
            'PARCELS_PARAM_VINE_L102_L201_L3010',
            'PARCELS_PARAM_VINE_L102_L201_L3011',
            'PARCELS_PARAM_VINE_L102_L201_L3012',
            'PARCELS_PARAM_VINE_L102_L201_L3013',
            'PARCELS_PARAM_VINE_L102_L201_L3014',
            'PARCELS_PARAM_VINE_L102_L201_L3015',
            'PARCELS_PARAM_VINE_L102_L201_L3016',
            'PARCELS_PARAM_VINE_L102_L201_L3017',
            'PARCELS_PARAM_VINE_L102_L201_L3018',
            'PARCELS_PARAM_VINE_L102_L202_L301',
            'PARCELS_PARAM_VINE_L102_L202_L302',
            'PARCELS_PARAM_VINE_L102_L202_L303',
            'PARCELS_PARAM_VINE_L102_L202_L304',
            'PARCELS_PARAM_VINE_L102_L202_L305',
            'PARCELS_PARAM_VINE_L102_L202_L306',
            'PARCELS_PARAM_VINE_L102_L202_L307',
            'PARCELS_PARAM_VINE_L102_L202_L308',
            'PARCELS_PARAM_VINE_L102_L202_L309',
            'PARCELS_PARAM_VINE_L102_L202_L3010',
            'PARCELS_PARAM_VINE_L102_L202_L3011',
            'PARCELS_PARAM_VINE_L102_L202_L3012',
            'PARCELS_PARAM_VINE_L102_L202_L3013',
            'PARCELS_PARAM_VINE_L102_L202_L3014',
            'PARCELS_PARAM_VINE_L102_L202_L3015',
            'PARCELS_PARAM_VINE_L102_L202_L3016',
            'PARCELS_PARAM_VINE_L102_L202_L3017',
            'PARCELS_PARAM_VINE_L102_L202_L3018',
            'PARCELS_PARAM_VINE_L102_L202_L3019',
            'PARCELS_PARAM_VINE_L102_L202_L3020',
            'PARCELS_PARAM_VINE_L102_L202_L3021',
            'PARCELS_PARAM_VINE_L102_L203_L301',
            'PARCELS_PARAM_VINE_L102_L203_L302',
            'PARCELS_PARAM_VINE_L102_L203_L303'
        ]);
}());
